/* eslint-disable react/react-in-jsx-scope */
import { IComponentProps, getUrlSync } from '@msdyn365-commerce/core';
import React from 'react';
import { IMoeSearchResultContainerResources } from '../moe-search-result-container.props.autogenerated';

export interface ICuratedCollectionComponentProps extends IComponentProps<{}> {
    className?: string;
    products: ICuratedCollection[];
    resources?: IMoeSearchResultContainerResources;
    preferredLanguageForError: string;
    preferredLanguageForButton: string;
}

export interface ICuratedCollection {
    productURL: string;
    imageURL: string;
    Name: string;
    Description: string;
    Count: string;
    recordId: number;
    channelId: number;
}

export const CuratedCollectionList: React.FC<ICuratedCollectionComponentProps> = (props: ICuratedCollectionComponentProps) => {
    const { products, resources, preferredLanguageForError } = props;

    const onClickCuratedCollection = (index: number, recordId: number) => {
        const wishlistURL: any = getUrlSync('wishlist', props.context.actionContext);
        const curatedCollectionUrl: any = wishlistURL.replace('wishlist', 'curatedcollection');
        const decodedCuratedCollectionURL = decodeURIComponent(curatedCollectionUrl);
        console.log(decodedCuratedCollectionURL);
        console.log('inside view collection click');
        const separator = decodedCuratedCollectionURL?.includes('?') ? '&' : '?';
        window.location.assign(`${decodedCuratedCollectionURL}${separator}rid=${recordId}`);
    };
    const descriptionLength = 350;

    return (
        <div className='search-results-panels'>
            {products.length > 0 ? (
                products.map((item: ICuratedCollection, idx: number) => {
                    return (
                        <a
                            key={idx}
                            style={{ cursor: 'pointer' }}
                            onClick={() => onClickCuratedCollection(idx, item.recordId)}
                            className='collectionCard'
                            id='collection-1994'
                        >
                            <div className='collectionCard__type'>
                                <span className='collectionCard__type__label'>Collection</span>
                                <svg
                                    className='collectionCard__type__icon'
                                    width='18px'
                                    height='18px'
                                    viewBox='0 0 1024 1024'
                                    style={{ display: 'inline-block', verticalAlign: 'middle' }}
                                >
                                    <path
                                        d='M825.56,259.08H475.8c-59.69,0-108.25,48.31-108.25,107.69V916.31c0,59.38,48.56,107.69,108.25,107.69H825.56c59.69,0,108.25-48.31,108.25-107.69V366.77C933.81,307.39,885.25,259.08,825.56,259.08Zm55.69,657.23c0,30.4-25,55.13-55.69,55.13H475.8c-30.71,0-55.69-24.73-55.69-55.13V366.77c0-30.4,25-55.13,55.69-55.13H825.56c30.71,0,55.69,24.73,55.69,55.13v549.54ZM281.43,237.23V786.77c0,30.4,24.98,55.13,55.69,55.13v52.56c-59.69,0-108.25-48.31-108.25-107.69V237.23c0-59.38,48.56-107.69,108.25-107.69H686.88c59.69,0,108.25,48.31,108.25,107.69H742.57c0-30.4-24.98-55.13-55.69-55.13H337.12C306.41,182.1,281.43,206.83,281.43,237.23ZM142.75,107.69V657.23c0,30.4,24.98,55.13,55.69,55.13v52.56c-59.69,0-108.25-48.31-108.25-107.69V107.69C90.19,48.31,138.75,0,198.44,0H548.2c59.69,0,108.26,48.31,108.26,107.69H603.9c0-30.4-24.99-55.13-55.7-55.13H198.44C167.73,52.56,142.75,77.29,142.75,107.69Z'
                                        style={{ fill: 'rgb(255, 255, 255)' }}
                                    />
                                </svg>
                            </div>
                            <div className='collectionCard__thumbnail msc-empty_image'>
                                {item.imageURL && (
                                    <img
                                        src={item.imageURL}
                                        alt='Te Reo Māori in English-medium schools: Curriculum level 6 lesson plans'
                                    />
                                )}
                            </div>
                            <div className='collectionCard__details'>
                                <div className='collectionCard__details__title'>{item.Name}</div>
                                <p className='collectionCard__details__description'>
                                    {`${item.Description.substring(0, descriptionLength)}...`}
                                </p>
                                {/* <p className='collectionCard__details__resourceCount'>
                                        <svg
                                            className='collectionCard__details__resourceCount__icon'
                                            width='13.5px'
                                            height='13.5px'
                                            viewBox='0 0 1024 1024'
                                            style={{ display: 'inline-block', verticalAlign: 'middle' }}
                                        >
                                            <path
                                                d='M825.56,259.08H475.8c-59.69,0-108.25,48.31-108.25,107.69V916.31c0,59.38,48.56,107.69,108.25,107.69H825.56c59.69,0,108.25-48.31,108.25-107.69V366.77C933.81,307.39,885.25,259.08,825.56,259.08Zm55.69,657.23c0,30.4-25,55.13-55.69,55.13H475.8c-30.71,0-55.69-24.73-55.69-55.13V366.77c0-30.4,25-55.13,55.69-55.13H825.56c30.71,0,55.69,24.73,55.69,55.13v549.54ZM281.43,237.23V786.77c0,30.4,24.98,55.13,55.69,55.13v52.56c-59.69,0-108.25-48.31-108.25-107.69V237.23c0-59.38,48.56-107.69,108.25-107.69H686.88c59.69,0,108.25,48.31,108.25,107.69H742.57c0-30.4-24.98-55.13-55.69-55.13H337.12C306.41,182.1,281.43,206.83,281.43,237.23ZM142.75,107.69V657.23c0,30.4,24.98,55.13,55.69,55.13v52.56c-59.69,0-108.25-48.31-108.25-107.69V107.69C90.19,48.31,138.75,0,198.44,0H548.2c59.69,0,108.26,48.31,108.26,107.69H603.9c0-30.4-24.99-55.13-55.7-55.13H198.44C167.73,52.56,142.75,77.29,142.75,107.69Z'
                                                style={{ fill: 'rgb(148, 149, 153)' }}
                                            />
                                        </svg>
                                        10
                                    </p> */}
                            </div>
                            <div className='collectionCard__resourceCount'>
                                <svg
                                    className='collectionCard__resourceCount__icon'
                                    width='108px'
                                    height='108px'
                                    viewBox='0 0 1024 1024'
                                    style={{ display: 'inline-block', verticalAlign: 'middle' }}
                                >
                                    <path
                                        d='M825.56,259.08H475.8c-59.69,0-108.25,48.31-108.25,107.69V916.31c0,59.38,48.56,107.69,108.25,107.69H825.56c59.69,0,108.25-48.31,108.25-107.69V366.77C933.81,307.39,885.25,259.08,825.56,259.08Zm55.69,657.23c0,30.4-25,55.13-55.69,55.13H475.8c-30.71,0-55.69-24.73-55.69-55.13V366.77c0-30.4,25-55.13,55.69-55.13H825.56c30.71,0,55.69,24.73,55.69,55.13v549.54ZM281.43,237.23V786.77c0,30.4,24.98,55.13,55.69,55.13v52.56c-59.69,0-108.25-48.31-108.25-107.69V237.23c0-59.38,48.56-107.69,108.25-107.69H686.88c59.69,0,108.25,48.31,108.25,107.69H742.57c0-30.4-24.98-55.13-55.69-55.13H337.12C306.41,182.1,281.43,206.83,281.43,237.23ZM142.75,107.69V657.23c0,30.4,24.98,55.13,55.69,55.13v52.56c-59.69,0-108.25-48.31-108.25-107.69V107.69C90.19,48.31,138.75,0,198.44,0H548.2c59.69,0,108.26,48.31,108.26,107.69H603.9c0-30.4-24.99-55.13-55.7-55.13H198.44C167.73,52.56,142.75,77.29,142.75,107.69Z'
                                        style={{ fill: 'rgb(248, 248, 244)' }}
                                    />
                                </svg>
                                <span className='collectionCard__resourceCount__count'>{item.Count}</span>
                                <span className='collectionCard__resourceCount__label'>Resources</span>
                            </div>
                        </a>
                    );
                })
            ) : (
                <div className='msc-alert msc-alert-warning'>
                    {preferredLanguageForError === 'maori'
                        ? resources?.resultSearchNotFoundTextInMaori
                        : resources?.resultSearchNotFoundText}
                    .
                </div>
            )}
        </div>
    );
};
